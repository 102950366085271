<template>
    <v-container>
        <VTopToolbar title="New task">
            <template v-slot:menu>
                <app-menu />
            </template>
        </VTopToolbar>

        <h1 class="headline">New</h1>
        In progress!
    </v-container>
</template>

<script>
export default {
};
</script>
